import { getUserRole, Role } from '@/auth/utils'
import management from './management'

export default () => {
  // Array of sections
  if (getUserRole() === Role.Admin) {
    return [...management]
  }
  return [];
};
