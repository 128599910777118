export default [
  {
    header: 'Ana Sayfa',
    route: 'manage-home',
    icon: 'HomeIcon',
  },
  {
    header: 'User Management',
    icon: 'UserIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Users',
        route: 'manage-user',
      },
      {
        title: 'User Save',
        route: 'manage-user-save',
        hidden: true,
      },
    ],
  },
]
